/** @format */

import "./App.css";

function App() {
	return (
		<div className="App">
      <div className="top"></div>
      <div className="middle">
      <h1 className="title">Goods Systems</h1>
			<h2 className="subtitle">
				12 years of design
				excellence meets ai-enhanced creativity.
			</h2>
			<p className="text">With over 12 years of experience in design
				and decoration, I specialize in harmonizing the best of both
				worlds – leveraging classic design tools and cutting-edge
				artificial intelligence models. My unique approach allows me
				to offer stunning and diverse design options, tailored to bring
				your ideas to life. My expertise lies in creating
				aesthetics that resonate with elegance, functionality, and
				futuristic vision. Let's embark on a design journey that
				transforms your vision into a beautiful reality.
			</p>
      <h4 className="sign-off">Sincerely,</h4>
      <h4 className="signature">Andrew Burnett</h4>
      </div>
      <div className="bottom">
      Contact <a className="contact" href="mailto:design@goods.systems">design@goods.systems</a>
      </div>
		</div>
	);
}

export default App;
